import React from "react";
import { useNavigate} from "react-router-dom";
import './ButtonHistory.css'

export const ButtonHistory = () => {
    let navigate = useNavigate();
    return (
        <>
          <button className="button button-history" onClick={() => navigate(-1)}>Назад</button> 
        </>
    );
};