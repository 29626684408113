import { useState, useEffect } from "react";
import React from "react";
import PopupWithForm from "../PopupWithForm/PopupWithForm";
import { AppContext } from "../non/contexts/AppContext";
import "./addFeedback.css";

function AddFeedback({ isOpen, setIsSubmitForm, setIsInfoTooltipPopup, setTextTooltip }) {
	//контекст состояния загрузки и функции закрытия попапа
	const value = React.useContext(AppContext);

	//переменные состояния инпутов
	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [description, setDescription] = useState("");

	// Состояния для валидации
	const [emailDirty, setEmailDirty] = useState(false);
	const [nameDirty, setNameDirty] = useState(false);

	//состояния ошибки
	const [nameError, setNameError] = useState("Имя не может быть пустым");
	const [emailError, setEmailError] = useState("email не может быть пустым");

	const [error, setError] = useState();

	// Создаём функции для изменения состояния имени и валидации имени.
	const changeName = (e) => {
		setName(e.target.value);
		const re = /^([а-я]{1}[а-яё]{1,23}|[a-z]{1}[a-z]{1,23})$/;
		if (!re.test(String(e.target.value).toLowerCase())) {
			setNameError("Некоректное имя");
		} else {
			setNameError("");
		}
	};

	// Создаём функции для изменения состояния имени и валидации email.
	const changeEmail = (e) => {
		setEmail(e.target.value);
		const re =
			/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

		if (!re.test(String(e.target.value).toLowerCase())) {
			setEmailError("Некоректный email");
		} else {
			setEmailError("");
		}
	};

	// Функция для изменения описания.
	const changeHandlerDescription = (e) => {
		setDescription(e.target.value);
	};

	// Валидации всей формы и кнопки отправки
	const [formValid, setFormValid] = useState(false);

	useEffect(() => {
		if (emailError || nameError) {
			setFormValid(false);
		} else {
			setFormValid(true);
		}
	}, [emailError, nameError]);

	const blurHandler = (e) => {
		switch (e.target.name) {
			case "name":
				setNameDirty(true);
				break;
			case "email":
				setEmailDirty(true);
				break;
			default:
		}
	};

	//функция проверки ответа от сервера и записи текста ошибки
	function checkResponse(res) {
		if (res.ok) {
			return res.json();
		}
		res.json().then((e) => {
			setError(Object.values(e).toString());
		});
		return Promise.reject(`Ошибка: ${res.status}`);
	}

	// Функция для отправки формы
	const submitData = (e) => {
		e.preventDefault();
		value.closeAllPopups();
		fetch("https://api-sopweb.ru/telegram", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({ name, email, description }),
		})
			.then(checkResponse)
			.then((res) => {
        setTextTooltip('Спасибо за отзыв!')
				setIsInfoTooltipPopup(true);
				setIsSubmitForm(true);
				setEmail("");
				setDescription("");
				setName("");
			})
			.catch((err) => {
				console.log(err);
				console.log("hhh");

				setIsSubmitForm(false);
				setIsInfoTooltipPopup(true);
			});
	};

	////переменная состояния нового отзыва
	//const [newFeedback, setNewFeedback] = React.useState({
	//	name: "",
	//	email: "",
	//	text: "",
	//});

	////создание нового отзыва в зависимости от открытия-закрытия попапа
	//React.useEffect(() => {
	//	setNewFeedback({
	//		name: "",
	//		email: "",
	//		feedback: "",
	//	});
	//}, [isOpen]);

	////функция получения данныз из импутов
	//const handleChange = (e) => {
	//	const { name, value } = e.target;
	//  //запись данных в новую карточку
	//	setNewFeedback({
	//    //добавление данных в массив
	//		...newFeedback,
	//    //имя инпута - его значение
	//		[name]: value,
	//	});
	//};

	////при сабмите отправка данных на сервер
	//function handleSubmit(e) {
	//	e.preventDefault();
	//	//onAddPlace({
	//	//	name: newPlace.name,
	//	//	link: newPlace.link,
	//	//});
	//	console.log(newFeedback);
	//  value.closeAllPopups()
	//}

	return (
		<PopupWithForm
			title="Добавить отзыв"
			name="feedback-add"
			buttonSubmitText="Отправить"
			isOpen={isOpen}
			onClose={value.closeAllPopups}
			onSubmit={submitData}
			onDisabled={!formValid}
		>
			<fieldset className="popup__fieldset">
				{nameDirty && nameError && <div className="error1">{nameError}</div>}
				<label className="popup__label">
					<input
						id="name-input"
						name="name"
						placeholder="Ваше имя"
						autoComplete="off"
						className="popup__input popup__input_data_location popup__input-first"
						type="text"
						required
						minLength="2"
						maxLength="30"
						value={name}
						onChange={changeName}
						onBlur={(e) => blurHandler(e)}
					/>
					<span className="place-input-error popup__input-error"></span>
				</label>
				{emailDirty && emailError && <div className="error2">{emailError}</div>}
				<label className="popup__label">
					<input
						id="email-input"
						name="email"
						placeholder="Ваша почта"
						autoComplete="off"
						className="popup__input popup__input_data_location popup__input-first"
						type="email"
						required
						minLength="2"
						maxLength="30"
						value={email || ""}
						onChange={changeEmail}
						onBlur={(e) => blurHandler(e)}
					/>
					<span className="place-input-error popup__input-error"></span>
				</label>
				<label className="popup__label popup__label_feedback">
					<textarea
						id="feedback-input"
						name="feedback"
						placeholder="Ваш отзыв"
						autoComplete="off"
						className="popup__input_data_link-foto popup__input"
						type="text"
						required
						rows="5"
						value={description || ""}
						onChange={changeHandlerDescription}
					/>
					<span className="link-input-error popup__input-error"></span>
				</label>
			</fieldset>
		</PopupWithForm>
	);
}

export default AddFeedback;
