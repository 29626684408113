import React from "react";
import Card from "../card/Card.js";
import "./Cards.css";

function Works({ handleCardClick, cards, styleName, title, cardNameClass }) {
	return (
		<main className="cards">
			<h1 className="cards__title">{title}</h1>
			<section className="grid-cards">
				{cards.map((card) => (
					<Card
						key={card.key}
						card={card}
						handleCardClick={handleCardClick}
						styleName={styleName}
            cardNameClass={cardNameClass}
					/>
				))}
			</section>
		</main>
	);
}

export default Works;
