import React, { useState, useEffect } from "react";
import Footer from "./footer/Footer.js";
import Header from "./Header/Header.js";
import Info from "./info/Info.js";
import {
	reviews,
	developmentTable,
	monumDuble,
	monumHorizont,
	monumRez,
	monumVert,
	ourWorks,
	developmentTile,
	developmentFences,
	developmentChips,
	developmentVases,
	decorationCandles,
	decorationFlowers,
	decorationCrosses,
	decorationIcons,
} from "./utils/constants.js";
import Reviews from "./Rewiews/Reviews.js";
import AddFeedback from "./addFeedback/AddFeedback.js";
import ImagePopup from "./imagePopup/ImagePopup.js";
import { AppContext } from "./non/contexts/AppContext.js";
import { Routes, Route, Navigate } from "react-router-dom";
import Nav from "./nav/nav.js";
import Promo from "./promo/Promo.js";
import Contacts from "./contacts/contacts.js";
import FeedbackPopup from "./feedbackPopup/FeedbackPopup.js";
import Cards from "./Cards/Cards.js";
import Development from "./development/Development.js";
import { ButtonHistory } from "./ButtonHistory/ButtonHistory.js";
import NavPopup from "./NavPopup/NavPopup.js";
import Monuments from "./monuments/Monuments.js";
import Decoration from "./decoration/Decoration.js";
import { Helmet } from "react-helmet-async";
import AddColback from "./AddColback/AddColback.jsx"
import InfoTooltip from "./InfoTooltip/InfoTooltip";
import imgOk from "../images/img-ok.png";
import imgNone from "../images/img-none.png";

function App() {
	const [selectedCard, setSelectedCard] = useState(null);
	const [selectedImage, setSelectedImage] = useState(null);
	const [isNavPopup, setIsNavPopup] = useState(false);
  const [isCalbackPopup, setIsCalbackPopup] = useState(false);
  const [isAddFeedbackPopupOpen, setIsAddFeedbackPopupOpen] = useState(false);
	const [isInfoTooltipPopup, setIsInfoTooltipPopup] = useState(false);
  const [isSubmitForm, setIsSubmitForm] = useState(false);
  const [textTooltip, setTextTooltip] = useState("");


	function onNavPpopup() {
		setIsNavPopup(true);
	}

  function onCalbackPopup() {
		setIsCalbackPopup(true);    
	}

	function closeAllPopups() {
		setSelectedCard(null);
		setSelectedImage(null);
		setIsNavPopup(false);
    setIsAddFeedbackPopupOpen(false);
    setIsCalbackPopup(false);
    setIsInfoTooltipPopup(false)
	}

	function handleCardClick(card) {
		setSelectedCard(card);
	}

	function handleImageClick(card) {
		setSelectedImage(card);
	}

  function onAddFeedback() {
		setIsAddFeedbackPopupOpen(true);
	}

  //закрытие тултипа
	useEffect(() => {
		if (isInfoTooltipPopup)
			setTimeout(() => {
				closeAllPopups();
			}, 3000);
	}, [isInfoTooltipPopup]);

	return (
		<AppContext.Provider value={{ closeAllPopups }}>
			<div className="page">
				<Header onCalbackPopup={onCalbackPopup} />
				<Routes>
					<Route
						path="/"
						element={
							<>
								<Nav onNavPopup={onNavPpopup} />
								<Promo />
								<Info />
							</>
						}
					/>
					<Route
						path="/contacts"
						element={
							<>
								<Nav onNavPopup={onNavPpopup} />
								<Contacts />
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/reviews"
						element={
							<>
								<Nav onNavPopup={onNavPpopup} />
								<Reviews onAddFeedback={onAddFeedback} handleCardClick={handleCardClick} cards={reviews} />
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/our-works"
						element={
							<>
								<Helmet>
									<link rel="canonical" href="https://mogil.ru/our-works" />
									<meta
										name="description"
										content="Вы можете ознакомиться с фотографиями некоторых из наших работ. Звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Наши работы по изготовлению памятников и благоустройства могил</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={ourWorks}
									styleName={"card__works"}
									handleCardClick={handleImageClick}
									title={"Наши работы"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/monuments"
						element={
							<>
								<Nav onNavPopup={onNavPpopup} />
								<Monuments />
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/monumentsRez"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/monumentsRez" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами резных гранитных памятников. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог резных гранитных памятников</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={monumRez}
									handleCardClick={handleImageClick}
									title={"Резные памятники"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/monumentsDuble"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/monumentsDuble" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами двойных гранитных памятников. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог двойных гранитных памятников</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={monumDuble}
									handleCardClick={handleImageClick}
									title={"Двойные памятники"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/monumentsHorizont"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/monumentsHorizont" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами горизонтальных гранитных памятников. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог горизонтальных гранитных памятников</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={monumHorizont}
									handleCardClick={handleImageClick}
									title={"Горизонтальные памятники"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/monumentsVert"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/monumentsVert" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами вертикальных гранитных памятников. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог вертикальных гранитных памятников</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={monumVert}
									handleCardClick={handleImageClick}
									title={"Вертикальные памятники"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/development"
						element={
							<>
								<Nav onNavPopup={onNavPpopup} />
								<Development />
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/fences"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/fences" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами оград для захронений. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог кладбищенских оград</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={developmentFences}
									handleCardClick={handleImageClick}
									title={"Ограды"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/tile"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/tile" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами тротуарной плитки для благоустройства захронений. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог плитки для оформления захронений</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={developmentTile}
									handleCardClick={handleImageClick}
									title={"Плитка"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/chips"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/chips" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами гранитной крошки для благоустройства захронений. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог гранитной крошки для оформления захронений</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={developmentChips}
									handleCardClick={handleImageClick}
									title={"Крошка"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/table"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/table" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами стольков и лавочек для благоустройства захронений. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог столиков и лавочек для оформления захронений</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={developmentTable}
									handleCardClick={handleImageClick}
									title={"Столики и лавочки"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/vase"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/vase" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами ваз и лампад для благоустройства захронений. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог ваз и лампад крошки для оформления захронений</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={developmentVases}
									handleCardClick={handleImageClick}
									title={"Вазы и лампады"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/decoration"
						element={
							<>
								<Nav onNavPopup={onNavPpopup} />
								<Decoration />
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/candles"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/candles" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами гравировки изображений свечей на гранитные памятники. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог изображений свечей для гравировки на памятники</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={decorationCandles}
									handleCardClick={handleImageClick}
									title={"Свечи"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/flowers"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/flowers" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами гравировки изображений цветов на гранитные памятники. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог изображений цветов для гравировки на памятники</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={decorationFlowers}
									handleCardClick={handleImageClick}
									title={"Цветы"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/crosses"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/crosses" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами гравировки изображений крестов на гранитные памятники. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог изображений крестов для гравировки на памятники</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={decorationCrosses}
									handleCardClick={handleImageClick}
									title={"Кресты"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route
						path="/icons"
						element={
							<>
              <Helmet>
									<link rel="canonical" href="https://mogil.ru/icons" />
									<meta
										name="description"
										content="Ознакомтесь с различными вариантами гравировки изображений икон на гранитные памятники. Для заказа звоните +7-977-310-84-61"
									/>
									<meta
										name="keywords"
										content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
									/>
									<title>Каталог изображений икон для гравировки на памятники</title>
								</Helmet>
								<Nav onNavPopup={onNavPpopup} />
								<Cards
									cards={decorationIcons}
									handleCardClick={handleImageClick}
									title={"Иконы"}
									cardNameClass={"card__name_type_monum"}
								/>
								<ButtonHistory />
							</>
						}
					/>
					<Route path="/*" element={<Navigate to="/" replace />} />
				</Routes>
				<Footer />
				{selectedImage && (
					<ImagePopup card={selectedImage} onClose={closeAllPopups} />
				)}
				{selectedCard && (
					<FeedbackPopup card={selectedCard} onClose={closeAllPopups} />
				)}
				<NavPopup
					//открытие попапа
					isOpen={isNavPopup ? "nav-popup nav-popup_openend" : "nav-popup"}
					onClose={closeAllPopups}
				/>
        <AddFeedback
					isOpen={isAddFeedbackPopupOpen && "add-popup_openend"}	
          setIsSubmitForm={setIsSubmitForm}			
          setIsInfoTooltipPopup={setIsInfoTooltipPopup}	
          setTextTooltip={setTextTooltip}	
				/>
        <AddColback
					isOpen={isCalbackPopup && "add-popup_openend"}
          setIsSubmitForm={setIsSubmitForm}		
          setIsInfoTooltipPopup={setIsInfoTooltipPopup}			
          setTextTooltip={setTextTooltip}	
				/>
        <InfoTooltip
					title={
            isSubmitForm
              ? textTooltip
              : "Что то пошло не так! Попробуйте еще раз!"
          }				
						isOpen={isInfoTooltipPopup ? "tooltip tooltip_openend" : "tooltip"}
            imgAlt={isSubmitForm ? "галочка" : "крестик"}
						imgSrc={isSubmitForm ? imgOk : imgNone}
						name={"infoTooltip"}
            setIsInfoTooltipPopup={setIsInfoTooltipPopup}
					/>
			</div>
		</AppContext.Provider>
	);
}

export default App;
