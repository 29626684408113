/* eslint-disable react/jsx-no-comment-textnodes */
import React from "react";
import Feedback from "../Feedback/Feedback.js";
import "./Rewiews.css";
import { Helmet } from "react-helmet-async";

function Reviews({ onAddFeedback, handleCardClick, cards }) {
	return (
    <>
    <Helmet>
    <link rel="canonical" href="https://mogil.ru/reviews" />
      <meta
        name="description"
        content="Перед заказом у нас памятника или оформления могилы ознакомтесь с отзывами реальных людей. Звоните +7-977-310-84-61"
      />
      <meta
        name="keywords"
        content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
      />
      <title>Отзывы людей, заказавших у нас памятники</title>
    </Helmet>
		<main className="reviws">
			<h1 className="rewiews__title">Отзывы</h1>
			<p className="rewiews__text">
				Мы стремимся стать лучше и будем рады любым Вашим отзывам о работе нашей
				компании. Свои мнения и пожелания Вы можете присылать нам на почту:
				<a
				href="mailto:blagoustroystvo_mogil@mail.ru"
				className="rewiews__mailto"
			>
				blagoustroystvo_mogil@mail.ru
			</a> или воспользоваться кнопкой добавить отзыв.
       Мы обязательно рассмотрим все Ваши
				сообщения и постараемся разъяснить все возникающие вопросы.
			</p>
      <button
				type="button"
				className="reviws__add-button button button_condition_hover"
				onClick={onAddFeedback}
			>
				Добавить отзыв
			</button>
			<section className="reviws__content">
				{cards.map((card) => (
					<Feedback
						key={card.text}
						card={card}
						handleCardClick={handleCardClick}
					/>
				))}
			</section>
		</main>
    </>
	);
}

export default Reviews;
