//компонент заголовка
import React from "react";
import { Link } from "react-router-dom";
//импорт картинки логотипа
import logo from "../../images/logo.png";
import './header.css'

//принимает пропсы заголовок ссылки, ссылка 
function Header({ onCalbackPopup }) {
	return (
		<header className="header">
      <Link to="/">
			<img src={logo} alt="логотип Памятный - М." className="header__logo" />
      </Link>
      <div className="header__title">
        изготовление памятников
      </div>
      <div className="header__contact">
      <a href="tel:+79773108461" className="header__contact_tel">+7-977-310-84-61</a>
      <a href="tel:+79670241427" className="header__contact_tel">+7-967-024-14-27</a>
      <a href="tel:+79779238285" className="header__contact_tel">+7-977-923-82-85</a>
      <a href="mailto:blagoustroystvo_mogil@mail.ru" className="header__contact_mail" >blagoustroystvo_mogil@mail.ru</a>
      <button onClick={onCalbackPopup} className="button__calback">Заказать звонок</button>
      </div>

		</header>
	);
}

export default Header;
