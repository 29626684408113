import React from "react";
import "./monuments.css";
import { Link } from "react-router-dom";
import monumentsLogo from "../../images/monuments-logo.jpeg";
import { Helmet } from "react-helmet-async";

function Monuments() {
	return (
		<>
			<Helmet>
				<link rel="canonical" href="https://mogil.ru/monuments" />
				<meta
					name="description"
					content="Резные монументы, памятники с
							крестом, березой, ангелом и другими символами, которые будут нести
							светлую память об усопшем Звоните +7-977-310-84-61"
				/>
				<meta
					name="keywords"
					content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
				/>
				<title>Каталог гранитных памятников различных форм и размеров</title>
			</Helmet>
			<section className="monuments">
				<div className="monuments__content">
					<img
						src={monumentsLogo}
						className="monuments__logo"
						alt="могила захронение"
					/>
					<div className="monuments__info">
						<h1 className="monuments__title">Гранитные памятники</h1>
						<p className="monuments__text">
							Воспоминания об ушедших близких и родных людей являются
							неотъемлемой частью нашей жизни.
						</p>
						<p className="monuments__text">
							Гранитное надгробие позволяет увековечить память усопшего. Этот
							материал имеет глубокий черный цвет и с годами не теряет свой
							внешний вид. На гранитный памятник можно нанести памятную
							гравировку с портретом или фотокерамикой, датами его жизни и
							смерти, эпитафией, выражающей ваши чувства.
						</p>
						<p className="monuments__text">
							В нашем каталоге представлен большой выбор гранитных памятников
							различных форм и размеров. Наряду с надгробиями простой
							геометрической формы представлены резные монументы, памятники с
							крестом, березой, ангелом и другими символами, которые будут нести
							светлую память об усопшем.
						</p>
					</div>
				</div>
				<div className="monuments__grid">
					<article className="monument-type">
						<Link
							to="/monumentsRez"
							className="button monuments__image-button popup__save_condition_hover"
						>
							<div className="monuments__image reznie">
								<p className="monuments__image-text">Резные</p>
							</div>
						</Link>
					</article>
					<article className="monument-type">
						<Link
							to="/monumentsDuble"
							className="button monuments__image-button popup__save_condition_hover"
						>
							<div className="monuments__image duble">
								<p className="monuments__image-text">Двойные</p>
							</div>
						</Link>
					</article>
					<article className="monument-type">
						<Link
							to="/monumentsVert"
							className="button monuments__image-button popup__save_condition_hover"
						>
							<div className="monuments__image vert">
								<p className="monuments__image-text">Вертикальные</p>
							</div>
						</Link>
					</article>
					<article className="monument-type">
						<Link
							to="/monumentsHorizont"
							className="button monuments__image-button popup__save_condition_hover"
						>
							<div className="monuments__image horizont">
								<p className="monuments__image-text">Горизонтальные</p>
							</div>
						</Link>
					</article>
				</div>
			</section>
		</>
	);
}

export default Monuments;
