import React from "react";
import './feedback.css';

function Feedback({handleCardClick, card}) {

	const cardClick = () => {
		handleCardClick(card);
	};

	return (
		<article className="feedback">
			<button
				type="button"
				className="button feedback__button button_condition_hover"
				onClick={cardClick}
			>
			<div className="feedback__content">  
				<h2 className="feedback__title">{card.name}</h2>
        <p className="feedback__date">{card.date}</p>
				<p className="feedback__text">
          {card.text}
				</p>
			</div>
      </button>
		</article>
	);
}

export default Feedback;
