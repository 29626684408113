import React from "react";
import "./development.css";
import { Link } from "react-router-dom";
import dev from "../../images/works/work9.jpeg";
import { Helmet } from "react-helmet-async";

function Development() {
	return (
    <>
    <Helmet>
      <link rel="canonical" href="https://mogil.ru/development" />
				<meta
					name="description"
					content="Установка
						нового надгробия, облагораживание окружающей территории, озеленение
						участка, монтаж декоративных элементов. Звоните +7-977-310-84-61"
				/>
				<meta
					name="keywords"
					content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
				/>
				<title>Благоустройство могилы, захронения</title>
			</Helmet>
		<section className="development">
			<div className="development__content">
				<img src={dev} className="development__logo" alt="могила захронение надгробие. " />
				<div className="development__info">
					<h1 className="development__title">Благоустройство могилы</h1>
					<p className="development__text">
						Работы по благоустройству могилы могут включать в себя установку
						нового надгробия, облагораживание окружающей территории, озеленение
						участка, а также монтаж декоративных элементов – гранитных лавочек,
						столиков, ваз и т.п. 
            </p>
            <p className="development__text">
            Доверять подобные работы рекомендуется
						исключительно профессионалам, поскольку в процессе обустройства
						места погребения следует учитывать не только личные предпочтения, но
						и характеристики конкретного участка, тип почвы, размеры памятника и
						прочие важные особенности.
					</p>
				</div>
			</div>
			<div className="development-servise">
				<h2 className="development-servise__title">
					Благоустройство может включать в себя следующие работы:
				</h2>
				<ul className="development-servise__list">
					<li className="development-servise__item">
						укладка тротуарной плитки, которая отграничивает могилу от соседних
						участков, придает ей завершенный и ухоженный вид
					</li>
					<li className="development-servise__item">
						установка основания для ограды, монтаж гранитных и кованых элементов
					</li>
					<li className="development-servise__item">
						озеленение могилы – высадка однолетних и многолетних растений,
						небольших деревьев или кустарников
					</li>
					<li className="development-servise__item">
						реставрация памятников, граверных элементов (портреты, надписи)
					</li>
				</ul>
			</div>
			<div className="development__grid">
				<article className="place">
					<Link
						to="/fences"
						className="button development__image-button popup__save_condition_hover"
					>
						<div className="development__image fences">
							<p className="development__image-text">Ограды</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/tile"
						className="button development__image-button popup__save_condition_hover"
					>
					<div className="development__image tile">
							<p className="development__image-text">Плитка</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/chips"
						className="button development__image-button popup__save_condition_hover"
					>
						<div className="development__image chips">
							<p className="development__image-text">Крошка</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/table"
						className="button development__image-button popup__save_condition_hover"
					>
						<div className="development__image table">
							<p className="development__image-text">Столики, лавочки</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/vase"
						className="button development__image-button popup__save_condition_hover"
					>
					<div className="development__image vase">
							<p className="development__image-text">Вазы, лампады</p>
						</div>
					</Link>
				</article>
			</div>
		</section>
    </>
	);
}

export default Development;
