/* eslint-disable jsx-a11y/iframe-has-title */
//компонент заголовка
import React from "react";
import tme from "../../images/tme_qc.png";
import inst from "../../images/inst__qc.jpeg";
import './contacts.css';
import { Helmet } from "react-helmet-async";

//принимает пропсы заголовок ссылки, ссылка
function Contacts() {
	return (
    <>
    <Helmet>
      <link rel="canonical" href="https://mogil.ru/contacts" />
				<meta
					name="description"
					content="Для заказа гранитного паиятника и благоустройства захронения наши контакты. Звоните +7-977-310-84-61"
				/>
				<meta
					name="keywords"
					content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
				/>
				<title>Наши контакты для заказа оформления могилы</title>
			</Helmet>
		<section className="contacts">
			<h1 className="contacts__title">Контакты</h1>
			<h2 className="contacts__subtitle">ИП Лейко Павел Геннадиевич</h2>
			<div className="contacts__adress">
				Адрес: 141552, Россия, Московская обл., Солнечногорский р-н, пос.
				Ржавки, корп. 1А.
			</div>
			<iframe
				className="contacts__iframe"
				src="https://yandex.ru/map-widget/v1/?um=constructor%3A4625c8be6423b785eca99f5d385e2cfc5a5f54f319804ee5e78875e6fd6b82e6&amp;source=constructor"
				//width="746"
				//height="400"
				frameBorder="0"
			></iframe>
			<div className="contacts__list">
				<a href="tel:+79773108461" className="contacts__item">
					<div>+7-977-310-84-61</div>
					<div>Павел</div>
				</a>
				<a href="tel:+79779238285" className="contacts__item">
					<div>+7-977-923-82-85</div>
					<div>рабочий</div>
				</a>
				<a href="tel:+79670241427" className="contacts__item">
					<div>+7-967-024-14-27</div>
					<div>Екатерина</div>
				</a>
			</div>
			<a
				href="mailto:blagoustroystvo_mogil@mail.ru"
				className="contacts__mailto"
			>
				blagoustroystvo_mogil@mail.ru
			</a>
			<div className="contacts__sosial">
				<a href="https://t.me/+79779238285" target="_blank" rel="noreferrer">
					<img
						src={tme}
						alt="телеграмм"
						className="contacts__tme"
					/>
				</a>
				<a
					href="https://www.instagram.com/blagoustroystvo_mogil?igsh=Nm5jaGJiZzFxdWp6&utm_source=qr"
					target="_blank"
					rel="noreferrer"
				>
					<img src={inst} alt="логотип." className="contacts__inst" />
				</a>
			</div>
		</section>
    </>
	);
}

export default Contacts;
