import React from "react";
import "./popupWithForm.css";

function PopupWithForm({
	title,
	name,
	buttonSubmitText,
	isOpen,
	onClose,
	onSubmit,
	onDisabled,
	children,
}) {
	React.useEffect(() => {
		if (!isOpen) return;

		function handleESC(e) {
			if (e.key === "Escape") {
				onClose();
			}
		}

		document.addEventListener("keydown", handleESC);

		return () => document.removeEventListener("keydown", handleESC);
	}, [isOpen]);

	function clickClosePopap(e) {
		if (e.target.classList.contains("add-popup_openend")) {
			onClose();
		}
	}

	return (
		<div
			className={`popup-with-form ${isOpen} popup_value_${name}`}
			onClick={clickClosePopap}
		>
			<div className="popup__container-form">
				<button
					type="button"
					className="button button_condition_hover popup-with-form__close"
					onClick={onClose}
				/>
				<h3 className="popup-with-form__title">{title}</h3>
				<form className="popup__form" name={name} onSubmit={onSubmit}>
					{children}
					<button
						type="submit"
						value="сохранить"
						className="popup-with-form__save button "
						disabled={onDisabled}
					>
						{buttonSubmitText}
					</button>
				</form>
			</div>
		</div>
	);
}

export default PopupWithForm;
