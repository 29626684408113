//компонент подвала сайта
import logo_black from "../../images/logo_black.png";
import insta from "../../images/insta_icon.png";
import React from "react";
import { Link } from "react-router-dom";
import "./footer.css";

function Footer() {
	return (
		<footer className="footer">
			<div className="footer__info">
      <Link to="/">
				<img src={logo_black} alt="логотип Памятный -М ." className="footer__logo" />
        </Link>
				<div className="footer__contact">
					<a href="tel:+79773108461" className="footer__contact_tel">
						+7-977-310-84-61
					</a>
					<a href="tel:+79670241427" className="footer__contact_tel">
						+7-967-024-14-27
					</a>
					<a href="tel:+79779238285" className="footer__contact_tel">
						+7-977-923-82-85
					</a>
					<a
						href="mailto:blagoustroystvo_mogil@mail.ru"
						className="footer__contact_mail"
					>
						blagoustroystvo_mogil@mail.ru
					</a>
					<a
						href="https://www.instagram.com/blagoustroystvo_mogil?igsh=Nm5jaGJiZzFxdWp6&utm_source=qr"
						className="footer__inst"
						target="_blank"
						rel="noreferrer"
					>
						<img src={insta} alt="инстаграмм ." width="20px" height="20px" />
					</a>
				</div>
				<nav className="footer__navigation">
					<ul className="link__list">
						<li className="link__item">
							<Link to="/" className="footer__link">
								Главная
							</Link>
						</li>
						<li className="link__item">
							<Link to="/monuments" className="footer__link">
								Памятники
							</Link>
						</li>
						<li className="link__item">
							<Link to="/development" className="footer__link">
								Благоустройство
							</Link>
						</li>
						<li className="link__item">
							<Link to="/decoration" className="footer__link">
								Оформление
							</Link>
						</li>
					</ul>
					<ul className="link__list">
						<li className="link__item">
							<Link to="/our-works" className="footer__link">
								Наши работы
							</Link>
						</li>
						<li className="link__item">
							<Link to="/contacts" className="footer__link">
								Контакты
							</Link>
						</li>
						<li className="link__item">
							<Link to="/reviews" className="footer__link">
								Отзывы
							</Link>
						</li>
					</ul>
				</nav>
			</div>
			<div className="footer__admin">
				<a href="https://sop-web.ru" className="footer__link-admin" target="_blank" rel="noreferrer">© {new Date().getFullYear()} SOP</a>
				{/*<Link to="/sign-in" onClick={e => e.preventDefault()} className="footer__link-admin footer__link-admin_disable" >
					Вход для администартора
				</Link>*/}
			</div>
		</footer>
	);
}

export default Footer;
