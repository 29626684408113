//компонент заголовка
import React from "react";
import "./info.css";

//принимает пропсы заголовок ссылки, ссылка
function Info() {
	return (
		<section id="aboutProject" className="info">
			<h2 className="info__title">
				В сфере благоустройства захоронения памятники имеют большую
				популярность.
			</h2>
			<div className="info__main">
				При изготовлении памятников мы используем только натуральный гранит!
			</div>
			<div className="info__main">
				В изготовление памятников мы учитываем желание заказчика на высоком и
				профессиональном уровне, используя только натуральный камень,
				преимущественно карельский{" "}
				<span
					data-hint="
                      Горная порода. Габбро-диабаз (также габбродолерит) — 
                      полнокристаллическая мелкозернистая вулканическая горная порода, химически и по минеральному составу близка к базальту.
                      Окраска габбро-диабаза тёмно-серая или зеленовато-чёрная. Структура диабазовая (офитовая), 
                      образована беспорядочно расположенными вытянутыми кристалликами плагиоклаза, 
                      промежутки между которыми заполнены авгитом.
                      "
					className="info__main_span"
				>
					габбро-диабаз
				</span>
				. Этот камень наиболее устойчив к погодным условиям и не требует
				дополнительного ухода. Так же возможно изготовление из других видов
				гранита.
			</div>
			<div className="servise">
				<h2 className="servise__title">Мы предлагаем следующие услуги:</h2>
				<ul className="servise__list">
					<li className="servise__item">
						Изготовление памятников, надгробий, цоколей, цветников, надгробных
						плит
					</li>
					<li className="servise__item">
						Граверные работы (надписи, портреты, эпитафии, фотокерамика,
						итальянская керамика, фацет, кресты и т.д.)
					</li>
					<li className="servise__item">
						Изготовление памятников по эскизам заказчика.
					</li>
					<li className="servise__item">
						Реставрация памятников (распил, шлифовка, полировка)
					</li>
					<li className="servise__item">Бесплатное хранение до установки.</li>
					<li className="servise__item">
						Доставка и установка памятников и надгробных сооружений на кладбищах
						Москвы и области
					</li>
					<li className="servise__item">
						Благоустройство надмогильных сооружений{" "}
						<span className="servise__item_span">
							(ограды, цветники, крошка гранит и мрамор, плитка и брусчатка,
							столики и лавочки, вазы и лампады, уборка, покраска и многое
							другое)
						</span>
					</li>
					<li className="servise__item">
						Предоставляем гарантии на изделия из гранита
					</li>
				</ul>
			</div>
			<div className="servise__contact">
				Подробнее по телефону
				<a href="tel:+79773108461" className="servise__contact_tel">
					+7-977-310-84-61
				</a>
				<a href="tel:+79670241427" className="servise__contact_tel">
					+7-967-024-14-27
				</a>
				<a href="tel:+79779238285" className="servise__contact_tel">
					+7-977-923-82-85
				</a>
			</div>
		</section>
	);
}

export default Info;
