import React from "react";
import logo from "../../images/image_page.png";
import "./promo.css";
import { Helmet } from "react-helmet-async";

const Promo = () => {
	return (
		<>
			<Helmet>
      <link rel="canonical" href="https://mogil.ru" />
				<meta
					name="description"
					content="Изготовление гранитных памятников, благоустройство захронений на кладбищах Москвы и Московской области. Звоните +7-977-310-84-61"
				/>
				<meta
					name="keywords"
					content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
				/>
				<title>Изготавливаем и устанавливаем памятники. Памятный - М</title>
			</Helmet>
			<section className="promo">
				<div className="promo__content">
					<div className="promo__info">
						<h1 className="promo__title">
							Изготавливаем и устанавливаем памятники на кладбищах Москвы и
							Московской области
						</h1>
						<a href="#aboutProject" className="link promo__link">
							Узнать больше
						</a>
					</div>
					<img src={logo} className="promo__logo" alt="надгробие" />
				</div>
			</section>
		</>
	);
};

export default Promo;
