import React from "react";
import "./decoration.css";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet-async";
import dec from "../../images/decoration/icons/icon3.jpg";

function Decoration() {
	return (
    <>
    <Helmet>
      <link rel="canonical" href="https://mogil.ru/decoration" />
				<meta
					name="description"
					content="Мы поможем Вам сохранить память
						о вашем близком человеке. Гравировка на памятнике - цветы, иконы, кресты и свечи. Звоните +7-977-310-84-61"
				/>
				<meta
					name="keywords"
					content="памятник, кладбище, захронение, похроны, надгробие, утановка гранитного памятника"
				/>
				<title>Оформление гранитных памятников. Гравировка</title>
			</Helmet>
		<section className="decoration">
			<div className="decoration__content">
				<img src={dec} className="decoration__logo" alt="Гравировка на гранитный памятник икона" />
				<div className="decoration__info">
					<h1 className="decoration__title">Оформление</h1>
					<p className="decoration__text">
						Памятник — это молчаливое напоминание об усопшем, дань уважения и
						любви, близких и родных к человеку, которого больше никогда не будет
						с ними рядом. Вся жизнь умещается в этих строках, выгравированных на
						холодном граните. 
            </p>
            <p className="decoration__text">
              Выбор памятника — это только начало обустройства
						места захоронения. На безликий монумент нужно нанести гравировку.
						Гранит — это плотный материал, поэтому на нем можно сделать рисунок
						любой сложности и не переживать, что с годами он сотрется. Если
						гравировка выполнена профессионально, то величественное надгробие
						будет долгие годы стоять в неизменном виде и повествовать о
						человеке, который под ним покоится. 
            </p>
            <p className="decoration__text">
            Мы поможем Вам сохранить память
						о вашем близком человеке. Гравировка на памятнике — это последнее,
						что вы можете сделать для усопшего. Пока жива память о человеке,
						жива и его душа.
					</p>
				</div>
			</div>
			<div className="decoration__grid">
				<article className="place">
					<Link
						to="/candles"
						className="button decoration__image-button popup__save_condition_hover"
					>
						<div className="decoration__image candles">
							<p className="decoration__image-text">Свечи</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/flowers"
						className="button decoration__image-button popup__save_condition_hover"
					>
						<div className="decoration__image flowers">
							<p className="decoration__image-text">Цветы</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/crosses"
						className="button decoration__image-button popup__save_condition_hover"
					>
						<div className="decoration__image crosses">
							<p className="decoration__image-text">Кресты</p>
						</div>
					</Link>
				</article>
				<article className="place">
					<Link
						to="/icons"
						className="button decoration__image-button popup__save_condition_hover"
					>
						<div className="decoration__image icones">
							<p className="decoration__image-text">Иконы</p>
						</div>
					</Link>
				</article>
			</div>
		</section>
    </>
	);
}

export default Decoration;
