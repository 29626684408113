/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Link } from "react-router-dom";
import "./navPopup.css";
import { AppContext } from "../non/contexts/AppContext";

function NavPopup({ isOpen }) {
	const value = React.useContext(AppContext);

	React.useEffect(() => {
		if (!isOpen) return;

		//закрытие по ESC
		function handleESC(e) {
			if (e.key === "Escape") {
				value.closeAllPopups();
			}
		}

		//слушатель клавиатуры
		document.addEventListener("keydown", handleESC);
		//отключаем слушатель
		return () => document.removeEventListener("keydown", handleESC);
		//зависимости открытие попапа и закрытие
	}, [isOpen, value.closeAllPopups]);

	//закрытие попапа по клику на область
	function clickClosePopap(e) {
		if (e.target.classList.contains("nav-popup_openend")) {
			value.closeAllPopups();
		}
	}

	return (
		<section className={isOpen} onClick={clickClosePopap}>
			<div className="nav-popup__container">
				<button
					type="button"
					className="nav-popup__close"
					onClick={value.closeAllPopups}
				/>
				<nav>
					<ul className="nav-popup__content">
						<li className="nav-popup__item">
							<Link
								to="/"
								className={
									window.location.pathname === "/"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Главная
							</Link>
						</li>
						<li className="nav-popup__item">
							<Link
								to="/monuments"
								className={
									window.location.pathname === "/monuments"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Памятники
							</Link>
						</li>
						<li className="nav-popup__item">
							<Link
								to="/development"
								className={
									window.location.pathname === "/development"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Благоустройство
							</Link>
						</li>
						<li className="nav-popup__item">
							<Link
								to="/decoration"
								className={
									window.location.pathname === "/decoration"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Оформление
							</Link>
						</li>
						<li className="nav-popup__item">
							<Link
								to="/our-works"
								className={
									window.location.pathname === "/our-works"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Наши работы
							</Link>
						</li>
						<li className="nav-popup__item">
							<Link
								to="/contacts"
								className={
									window.location.pathname === "/contacts"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Контакты
							</Link>
						</li>
						<li className="nav-popup__item">
							<Link
								to="/reviews"
								className={
									window.location.pathname === "/reviews"
										? "navigation__link disabled-link"
										: "navigation__link"
								}
                onClick={value.closeAllPopups}
							>
								Отзывы
							</Link>
						</li>
					</ul>
				</nav>
			</div>
		</section>
	);
}

export default NavPopup;
