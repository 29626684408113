import React from "react";
import './feedbackPopup.css'

function FeedbackPopup({ card, onClose }) {
	React.useEffect(() => {
		if (!card) return;

		function handleESC(e) {
			if (e.key === "Escape") {
				onClose();
			}
		}

		document.addEventListener("keydown", handleESC);

		return () => document.removeEventListener("keydown", handleESC);
	}, [card]);

	function clickClosePopap(e) {
		//  //если область клика содержит дочерний элемент - открытый попап
		if (e.target.classList.contains("popup_openend")) {
			onClose();
		}
	}
	return (
		<div
			className="popup popup_value_feedback popup_openend"
			onClick={clickClosePopap}
		>
			<div className="popup__feedback">
				<button
					type="button"
					onClick={onClose}
					className="popup__close popup__close_value_feedback button button_condition_hover"
				></button>
				<section className="popup-feedback">
					<h2 className="popup-feedback__title">{card.name}</h2>
					<p className="popup-feedback__date">{card.date}</p>
					<p className="popup-feedback__text">{card.text}</p>
				</section>
			</div>
		</div>
	);
}

export default FeedbackPopup;
