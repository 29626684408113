//компонент заголовка
import React from "react";
import { Link } from "react-router-dom";
import "./nav.css";

//принимает пропсы заголовок ссылки, ссылка
function Nav({ onNavPopup }) {
	return (
		<nav className="navigation">
			<ul className="navigation__list">
				<li className="navigation__item">
					<Link
						to="/"
						className={
							window.location.pathname === "/"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Главная
					</Link>
				</li>
				<li className="navigation__item">
					<Link
						to="/monuments"
						className={
							window.location.pathname === "/monuments"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Памятники
					</Link>
				</li>
				<li className="navigation__item">
					<Link
						to="/development"
						className={
							window.location.pathname === "/development"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Благоустройство
					</Link>
				</li>
				<li className="navigation__item">
					<Link
						to="/decoration"
						className={
							window.location.pathname === "/decoration"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Оформление
					</Link>
				</li>
				<li className="navigation__item">
					<Link
						to="/our-works"
						className={
							window.location.pathname === "/our-works"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Наши работы
					</Link>
				</li>
				<li className="navigation__item">
					<Link
						to="/contacts"
						className={
							window.location.pathname === "/contacts"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Контакты
					</Link>
				</li>
				<li className="navigation__item">
					<Link
						to="/reviews"
						className={
							window.location.pathname === "/reviews"
								? "navigation__link disabled-link"
								: "navigation__link"
						}
					>
						Отзывы
					</Link>
				</li>
			</ul>

			<button
				className="button-popup"
				type="button"
				onClick={onNavPopup}
			></button>
		</nav>
	);
}

export default Nav;
