//наши работы
import work74 from "../../images/works/work74.jpeg";
import work73 from "../../images/works/work73.jpeg";
import work72 from "../../images/works/work72.jpeg";
import work71 from "../../images/works/work71.jpeg";
import work70 from "../../images/works/work70.jpeg";
import work69 from "../../images/works/work69.jpeg";
import work68 from "../../images/works/work68.jpeg";
import work67 from "../../images/works/work67.jpeg";
import work66 from "../../images/works/work66.jpeg";
import work65 from "../../images/works/work65.jpeg";
import work64 from "../../images/works/work64.jpeg";
import work63 from "../../images/works/work63.jpeg";
import work62 from "../../images/works/work62.jpeg";
import work61 from "../../images/works/work61.jpeg";
import work60 from "../../images/works/work60.jpeg";
import work59 from "../../images/works/work59.jpeg";
import work58 from "../../images/works/work58.jpeg";
import work57 from "../../images/works/work57.jpeg";
import work56 from "../../images/works/work56.jpeg";
import work55 from "../../images/works/work55.jpeg";
import work54 from "../../images/works/work54.jpeg";
import work53 from "../../images/works/work53.jpeg";
import work52 from "../../images/works/work52.jpeg";
import work51 from "../../images/works/work51.jpeg";
import work50 from "../../images/works/work50.jpeg";
import work49 from "../../images/works/work49.jpeg";
import work48 from "../../images/works/work48.jpeg";
import work47 from "../../images/works/work47.jpeg";
import work46 from "../../images/works/work46.jpeg";
import work45 from "../../images/works/work45.jpeg";
import work44 from "../../images/works/work44.jpeg";
import work43 from "../../images/works/work43.jpeg";
import work42 from "../../images/works/work42.jpeg";
import work41 from "../../images/works/work41.jpeg";
import work40 from "../../images/works/work40.jpeg";
import work39 from "../../images/works/work39.jpeg";
import work38 from "../../images/works/work38.jpeg";
import work37 from "../../images/works/work37.jpeg";
import work36 from "../../images/works/work36.jpeg";
import work35 from "../../images/works/work35.jpeg";
import work34 from "../../images/works/work34.jpeg";
import work33 from "../../images/works/work33.jpeg";
import work32 from "../../images/works/work32.jpeg";
import work31 from "../../images/works/work31.jpeg";
import work30 from "../../images/works/work30.jpeg";
import work29 from "../../images/works/work29.jpeg";
import work28 from "../../images/works/work28.jpeg";
import work27 from "../../images/works/work27.jpeg";
import work26 from "../../images/works/work26.jpeg";
import work25 from "../../images/works/work25.jpeg";
import work24 from "../../images/works/work24.jpeg";
import work23 from "../../images/works/work23.jpeg";
import work22 from "../../images/works/work22.jpeg";
import work21 from "../../images/works/work21.jpeg";
import work20 from "../../images/works/work20.jpeg";
import work19 from "../../images/works/work19.jpeg";
import work18 from "../../images/works/work18.jpeg";
import work17 from "../../images/works/work17.jpeg";
import work16 from "../../images/works/work16.jpeg";
import work15 from "../../images/works/work15.jpeg";
import work14 from "../../images/works/work14.jpeg";
import work13 from "../../images/works/work13.jpeg";
import work12 from "../../images/works/work12.jpeg";
import work11 from "../../images/works/work11.jpeg";
import work10 from "../../images/works/work10.jpeg";
import work9 from "../../images/works/work9.jpeg";
import work8 from "../../images/works/work8.jpeg";
import work7 from "../../images/works/work7.jpeg";
import work6 from "../../images/works/work6.jpeg";
import work5 from "../../images/works/work5.jpeg";
import work4 from "../../images/works/work4.jpeg";
import work3 from "../../images/works/work3.jpeg";
import work2 from "../../images/works/work2.jpeg";
import work1 from "../../images/works/work1.jpeg";

//памятники резные
import monumentsRez1 from "../../images/monuments/reznie/pr-1.jpg";
import monumentsRez2 from "../../images/monuments/reznie/pr-2.jpg";
import monumentsRez3 from "../../images/monuments/reznie/pr-3.jpg";
import monumentsRez4 from "../../images/monuments/reznie/pr-4.jpg";
import monumentsRez5 from "../../images/monuments/reznie/pr-5.jpg";
import monumentsRez6 from "../../images/monuments/reznie/pr-6.jpg";
import monumentsRez7 from "../../images/monuments/reznie/pr-7.jpg";
import monumentsRez8 from "../../images/monuments/reznie/pr-8.jpg";
import monumentsRez9 from "../../images/monuments/reznie/pr-9.jpg";
import monumentsRez10 from "../../images/monuments/reznie/pr-10.jpg";
import monumentsRez11 from "../../images/monuments/reznie/pr-11.jpg";
import monumentsRez12 from "../../images/monuments/reznie/pr-12.jpg";
import monumentsRez13 from "../../images/monuments/reznie/pr-13.jpg";
import monumentsRez14 from "../../images/monuments/reznie/pr-14.jpg";
import monumentsRez15 from "../../images/monuments/reznie/pr-15.jpg";
import monumentsRez16 from "../../images/monuments/reznie/pr-16.jpg";
import monumentsRez17 from "../../images/monuments/reznie/pr-17.jpg";
import monumentsRez18 from "../../images/monuments/reznie/pr-18.jpg";
import monumentsRez19 from "../../images/monuments/reznie/pr-19.jpg";
import monumentsRez20 from "../../images/monuments/reznie/pr-20.jpg";
import monumentsRez21 from "../../images/monuments/reznie/pr-21.jpg";
import monumentsRez22 from "../../images/monuments/reznie/pr-22.jpg";
import monumentsRez23 from "../../images/monuments/reznie/pr-23.jpg";
import monumentsRez24 from "../../images/monuments/reznie/pr-24.jpg";
import monumentsRez25 from "../../images/monuments/reznie/pr-25.jpg";
import monumentsRez26 from "../../images/monuments/reznie/pr-26.jpg";
import monumentsRez27 from "../../images/monuments/reznie/pr-27.jpg";
import monumentsRez28 from "../../images/monuments/reznie/pr-28.jpg";
import monumentsRez29 from "../../images/monuments/reznie/pr-29.jpg";
import monumentsRez30 from "../../images/monuments/reznie/pr-30.jpg";
import monumentsRez31 from "../../images/monuments/reznie/pr-31.jpg";
import monumentsRez32 from "../../images/monuments/reznie/pr-32.jpg";
import monumentsRez33 from "../../images/monuments/reznie/pr-33.jpg";
import monumentsRez34 from "../../images/monuments/reznie/pr-34.jpg";
import monumentsRez35 from "../../images/monuments/reznie/pr-35.jpg";
import monumentsRez36 from "../../images/monuments/reznie/pr-36.jpg";
import monumentsRez37 from "../../images/monuments/reznie/pr-37.jpg";
//import monumentsRez38 from "../../images/monuments/reznie/pr-38.jpg";
import monumentsRez39 from "../../images/monuments/reznie/pr-39.jpg";
import monumentsRez40 from "../../images/monuments/reznie/pr-40.jpg";
import monumentsRez41 from "../../images/monuments/reznie/pr-41.jpg";
import monumentsRez42 from "../../images/monuments/reznie/pr-42.jpg";
import monumentsRez43 from "../../images/monuments/reznie/pr-43.jpg";
import monumentsRez44 from "../../images/monuments/reznie/pr-44.jpg";
import monumentsRez45 from "../../images/monuments/reznie/pr-45.jpg";
import monumentsRez46 from "../../images/monuments/reznie/pr-46.jpg";
import monumentsRez47 from "../../images/monuments/reznie/pr-47.jpg";
import monumentsRez48 from "../../images/monuments/reznie/pr-48.jpg";
import monumentsRez49 from "../../images/monuments/reznie/pr-49.jpg";
import monumentsRez50 from "../../images/monuments/reznie/pr-50.jpg";
import monumentsRez51 from "../../images/monuments/reznie/pr-51.jpg";
import monumentsRez52 from "../../images/monuments/reznie/pr-52.jpg";
import monumentsRez53 from "../../images/monuments/reznie/pr-53.jpg";

//памятники двойные
import monumentsDuble1 from "../../images/monuments/duble/dp1.jpg";
import monumentsDuble2 from "../../images/monuments/duble/dp2.jpg";
import monumentsDuble3 from "../../images/monuments/duble/dp3.jpg";
import monumentsDuble4 from "../../images/monuments/duble/dp4.jpg";
import monumentsDuble5 from "../../images/monuments/duble/dp5.jpg";
import monumentsDuble6 from "../../images/monuments/duble/dp6.jpg";
import monumentsDuble7 from "../../images/monuments/duble/dp7.jpg";

//памятники горизонтальные
import monumentsHorizont1 from "../../images/monuments/horizont/pg41.jpg";
import monumentsHorizont2 from "../../images/monuments/horizont/pg43.jpg";
import monumentsHorizont3 from "../../images/monuments/horizont/pg44.jpg";
import monumentsHorizont4 from "../../images/monuments/horizont/pg45.jpg";
import monumentsHorizont5 from "../../images/monuments/horizont/pg46.jpg";
import monumentsHorizont6 from "../../images/monuments/horizont/pg47.jpg";
import monumentsHorizont7 from "../../images/monuments/horizont/pg48.jpg";
import monumentsHorizont8 from "../../images/monuments/horizont/pg49.jpg";
import monumentsHorizont9 from "../../images/monuments/horizont/pg37.jpg";

//памятники вертикальные
import monumentsVert1 from "../../images/monuments/vert/pv49.jpg";
import monumentsVert2 from "../../images/monuments/vert/pv50.jpg";
import monumentsVert3 from "../../images/monuments/vert/pv51.jpg";
import monumentsVert4 from "../../images/monuments/vert/pv52.jpg";
import monumentsVert5 from "../../images/monuments/vert/pv53.jpg";
import monumentsVert6 from "../../images/monuments/vert/pv54.jpg";
import monumentsVert7 from "../../images/monuments/vert/pv55.jpg";
import monumentsVert8 from "../../images/monuments/vert/pv56.jpg";
import monumentsVert9 from "../../images/monuments/vert/pv57.jpg";

//столы и лавочки
import table1 from "../../images/development/tables/table1.jpg";
import table2 from "../../images/development/tables/table2.jpg";
import table3 from "../../images/development/tables/table3.jpg";
import table4 from "../../images/development/tables/table4.jpg";
import table5 from "../../images/development/tables/table5.jpg";

//плитка
import tile1 from "../../images/development/tile/tile1.jpg";
import tile2 from "../../images/development/tile/tile2.jpg";
import tile3 from "../../images/development/tile/tile3.jpg";
import tile4 from "../../images/development/tile/tile4.jpg";
import tile5 from "../../images/development/tile/tile5.jpg";
import tile6 from "../../images/development/tile/tile6.png";
import tile7 from "../../images/development/tile/tile7.jpg";

//ограды
import fences1 from "../../images/development/fences/fences1.jpeg";
import fences2 from "../../images/development/fences/fences2.jpeg";
import fences3 from "../../images/development/fences/fences3.jpeg";
import fences4 from "../../images/development/fences/fences4.jpeg";
import fences5 from "../../images/development/fences/fences5.jpeg";
import fences6 from "../../images/development/fences/fences6.jpeg";
import fences7 from "../../images/development/fences/fences7.jpeg";
import fences8 from "../../images/development/fences/fences8.jpeg";
import fences9 from "../../images/development/fences/fences9.jpg";
import fences10 from "../../images/development/fences/fences10.jpeg";
import fences11 from "../../images/development/fences/fences11.jpeg";
import fences12 from "../../images/development/fences/fences12.jpg";
import fences13 from "../../images/development/fences/fences13.jpeg";
import fences14 from "../../images/development/fences/fences14.jpeg";
import fences15 from "../../images/development/fences/fences15.jpg";
import fences16 from "../../images/development/fences/fences16.jpeg";
import fences17 from "../../images/development/fences/fences17.jpg";
import fences18 from "../../images/development/fences/fences18.jpeg";
import fences19 from "../../images/development/fences/fences19.jpeg";
import fences20 from "../../images/development/fences/fences20.jpg";
import fences21 from "../../images/development/fences/fences21.jpg";
import fences22 from "../../images/development/fences/fences22.jpeg";
import fences23 from "../../images/development/fences/fences23.jpg";
import fences24 from "../../images/development/fences/fences24.jpg";

//крошка
import chip1 from "../../images/development/chips/chip1.jpeg";
import chip2 from "../../images/development/chips/chip2.jpeg";
import chip3 from "../../images/development/chips/chip3.jpeg";
import chip4 from "../../images/development/chips/chip4.jpeg";
import chip5 from "../../images/development/chips/chip5.jpeg";
import chip6 from "../../images/development/chips/chip6.jpeg";

//вазы и лампады
import vase1 from "../../images/development/vase/vase1.jpeg";
import vase2 from "../../images/development/vase/vase2.jpeg";
import vase3 from "../../images/development/vase/vase3.jpeg";
import vase4 from "../../images/development/vase/vase4.jpeg";
import vase5 from "../../images/development/vase/vase5.jpeg";
import vase6 from "../../images/development/vase/vase6.jpeg";
import vase7 from "../../images/development/vase/vase7.jpeg";
import vase8 from "../../images/development/vase/vase8.jpeg";
import vase9 from "../../images/development/vase/vase9.jpeg";
import vase10 from "../../images/development/vase/vase10.jpeg";

//свечи
import candle1 from "../../images/decoration/candles/candle1.jpg";
import candle2 from "../../images/decoration/candles/candle2.jpg";
import candle3 from "../../images/decoration/candles/candle3.png";
//import candle4 from "../../images/decoration/candles/candle4.jpg";
import candle5 from "../../images/decoration/candles/candle5.jpg";
import candle6 from "../../images/decoration/candles/candle6.bmp";
import candle7 from "../../images/decoration/candles/candle7.jpg";
import candle8 from "../../images/decoration/candles/candle8.png";
import candle9 from "../../images/decoration/candles/candle9.jpg";
import candle10 from "../../images/decoration/candles/candle10.png";
//import candle11 from "../../images/decoration/candles/candle11.jpg";
//import candle12 from "../../images/decoration/candles/candle12.jpg";
import candle13 from "../../images/decoration/candles/candle13.png";
import candle14 from "../../images/decoration/candles/candle14.jpg";
import candle15 from "../../images/decoration/candles/candle15.jpg";
import candle16 from "../../images/decoration/candles/candle16.jpg";
//import candle17 from "../../images/decoration/candles/candle17.jpg";
//import candle18 from "../../images/decoration/candles/candle18.jpg";
import candle19 from "../../images/decoration/candles/candle19.jpg";
import candle20 from "../../images/decoration/candles/candle20.jpg";
//import candle21 from "../../images/decoration/candles/candle21.webp";
import candle22 from "../../images/decoration/candles/candle22.jpg";
//import candle23 from "../../images/decoration/candles/candle23.jpg";
import candle24 from "../../images/decoration/candles/candle24.jpg";
import candle25 from "../../images/decoration/candles/candle25.jpg";
import candle26 from "../../images/decoration/candles/candle26.jpg";
import candle27 from "../../images/decoration/candles/candle27.jpg";
//import candle28 from "../../images/decoration/candles/candle28.webp";
import candle29 from "../../images/decoration/candles/candle29.jpg";

//цветы
import flower1 from "../../images/decoration/flowers/flower1.png";
import flower2 from "../../images/decoration/flowers/flower2.jpg";
import flower3 from "../../images/decoration/flowers/flower3.jpg";
import flower4 from "../../images/decoration/flowers/flower4.jpg";
import flower5 from "../../images/decoration/flowers/flower5.jpg";
import flower6 from "../../images/decoration/flowers/flower6.jpg";
import flower7 from "../../images/decoration/flowers/flower7.png";
import flower8 from "../../images/decoration/flowers/flower8.jpg";
import flower9 from "../../images/decoration/flowers/flower9.bmp";
import flower10 from "../../images/decoration/flowers/flower10.png";
import flower11 from "../../images/decoration/flowers/flower11.jpg";
import flower12 from "../../images/decoration/flowers/flower12.png";
import flower13 from "../../images/decoration/flowers/flower13.jpg";
import flower14 from "../../images/decoration/flowers/flower14.jpg";
import flower15 from "../../images/decoration/flowers/flower15.jpg";
import flower16 from "../../images/decoration/flowers/flower16.jpg";
import flower17 from "../../images/decoration/flowers/flower17.jpg";
import flower18 from "../../images/decoration/flowers/flower18.jpg";
import flower19 from "../../images/decoration/flowers/flower19.jpg";
import flower20 from "../../images/decoration/flowers/flower20.jpg";
import flower21 from "../../images/decoration/flowers/flower21.jpg";
import flower22 from "../../images/decoration/flowers/flower22.jpg";
import flower23 from "../../images/decoration/flowers/flower23.jpg";
import flower24 from "../../images/decoration/flowers/flower24.png";
import flower25 from "../../images/decoration/flowers/flower25.jpg";
import flower26 from "../../images/decoration/flowers/flower26.jpg";
import flower27 from "../../images/decoration/flowers/flower27.jpg";
import flower28 from "../../images/decoration/flowers/flower28.png";
import flower29 from "../../images/decoration/flowers/flower29.jpg";
import flower30 from "../../images/decoration/flowers/flower30.jpg";
import flower31 from "../../images/decoration/flowers/flower31.jpg";
import flower32 from "../../images/decoration/flowers/flower32.jpg";
import flower33 from "../../images/decoration/flowers/flower33.jpg";
import flower34 from "../../images/decoration/flowers/flower34.jpg";
import flower35 from "../../images/decoration/flowers/flower35.jpg";
import flower36 from "../../images/decoration/flowers/flower36.jpg";
//import flower37 from "../../images/decoration/flowers/flower37.png";
//import flower38 from "../../images/decoration/flowers/flower38.jpg";
import flower39 from "../../images/decoration/flowers/flower39.jpg";
import flower40 from "../../images/decoration/flowers/flower40.jpg";
//import flower41 from "../../images/decoration/flowers/flower41.jpg";
import flower42 from "../../images/decoration/flowers/flower42.jpg";
import flower43 from "../../images/decoration/flowers/flower43.jpg";
import flower44 from "../../images/decoration/flowers/flower44.jpg";
import flower45 from "../../images/decoration/flowers/flower45.jpg";
import flower46 from "../../images/decoration/flowers/flower46.png";
import flower47 from "../../images/decoration/flowers/flower47.jpg";
import flower48 from "../../images/decoration/flowers/flower48.jpg";
import flower49 from "../../images/decoration/flowers/flower49.gif";
import flower50 from "../../images/decoration/flowers/flower50.png";
import flower51 from "../../images/decoration/flowers/flower51.png";
import flower52 from "../../images/decoration/flowers/flower52.jpg";
import flower53 from "../../images/decoration/flowers/flower53.jpg";
import flower54 from "../../images/decoration/flowers/flower54.jpg";
import flower55 from "../../images/decoration/flowers/flower55.jpg";
import flower56 from "../../images/decoration/flowers/flower56.jpg";
import flower57 from "../../images/decoration/flowers/flower57.jpg";
import flower58 from "../../images/decoration/flowers/flower58.jpg";
import flower59 from "../../images/decoration/flowers/flower59.jpg";
import flower60 from "../../images/decoration/flowers/flower60.jpg";
import flower61 from "../../images/decoration/flowers/flower61.png";
import flower62 from "../../images/decoration/flowers/flower62.jpg";
import flower63 from "../../images/decoration/flowers/flower63.jpg";
import flower64 from "../../images/decoration/flowers/flower64.png";
import flower65 from "../../images/decoration/flowers/flower65.gif";
import flower66 from "../../images/decoration/flowers/flower66.bmp";
import flower67 from "../../images/decoration/flowers/flower67.jpg";
import flower68 from "../../images/decoration/flowers/flower68.jpg";
import flower69 from "../../images/decoration/flowers/flower69.jpg";
import flower70 from "../../images/decoration/flowers/flower70.jpg";
import flower71 from "../../images/decoration/flowers/flower71.jpg";
import flower72 from "../../images/decoration/flowers/flower72.jpg";
import flower73 from "../../images/decoration/flowers/flower73.jpg";
import flower74 from "../../images/decoration/flowers/flower74.jpg";
import flower75 from "../../images/decoration/flowers/flower75.jpg";

//кресты
import cross1 from "../../images/decoration/crosses/cross1.jpg";
import cross2 from "../../images/decoration/crosses/cross2.jpg";
import cross3 from "../../images/decoration/crosses/cross3.jpg";
import cross4 from "../../images/decoration/crosses/cross4.jpg";
import cross5 from "../../images/decoration/crosses/cross5.jpeg";
import cross6 from "../../images/decoration/crosses/cross6.jpg";
import cross7 from "../../images/decoration/crosses/cross7.png";
import cross8 from "../../images/decoration/crosses/cross8.png";
import cross9 from "../../images/decoration/crosses/cross9.jpg";
import cross10 from "../../images/decoration/crosses/cross10.png";
import cross11 from "../../images/decoration/crosses/cross11.png";
import cross12 from "../../images/decoration/crosses/cross12.jpg";
import cross13 from "../../images/decoration/crosses/cross13.png";
import cross14 from "../../images/decoration/crosses/cross14.png";
import cross15 from "../../images/decoration/crosses/cross15.jpg";
import cross16 from "../../images/decoration/crosses/cross16.jpg";
import cross17 from "../../images/decoration/crosses/cross17.jpg";
import cross18 from "../../images/decoration/crosses/cross18.jpg";
import cross19 from "../../images/decoration/crosses/cross19.bmp";
import cross20 from "../../images/decoration/crosses/cross20.jpg";
import cross21 from "../../images/decoration/crosses/cross21.jpg";
import cross22 from "../../images/decoration/crosses/cross22.jpg";
import cross23 from "../../images/decoration/crosses/cross23.jpg";
import cross24 from "../../images/decoration/crosses/cross24.jpg";
import cross25 from "../../images/decoration/crosses/cross25.jpg";
import cross26 from "../../images/decoration/crosses/cross26.jpg";
import cross27 from "../../images/decoration/crosses/cross27.jpg";
import cross28 from "../../images/decoration/crosses/cross28.jpg";
import cross29 from "../../images/decoration/crosses/cross29.jpg";
import cross30 from "../../images/decoration/crosses/cross30.jpg";
import cross31 from "../../images/decoration/crosses/cross31.jpg";
//import cross32 from "../../images/decoration/crosses/cross32.webp";
import cross33 from "../../images/decoration/crosses/cross33.jpg";
import cross34 from "../../images/decoration/crosses/cross34.jpg";
import cross35 from "../../images/decoration/crosses/cross35.jpg";
import cross36 from "../../images/decoration/crosses/cross36.jpg";
import cross37 from "../../images/decoration/crosses/cross37.jpg";
import cross38 from "../../images/decoration/crosses/cross38.jpg";
import cross39 from "../../images/decoration/crosses/cross39.jpg";
//import cross40 from "../../images/decoration/crosses/cross40.jpg";
import cross41 from "../../images/decoration/crosses/cross41.png";
//import cross42 from "../../images/decoration/crosses/cross42.jpg";

//иконы
import icon1 from "../../images/decoration/icons/icon1.jpg";
import icon2 from "../../images/decoration/icons/icon2.jpg";
import icon3 from "../../images/decoration/icons/icon3.jpg";
import icon4 from "../../images/decoration/icons/icon4.png";
import icon5 from "../../images/decoration/icons/icon5.jpg";
import icon6 from "../../images/decoration/icons/icon6.jpg";
import icon7 from "../../images/decoration/icons/icon7.jpg";
import icon8 from "../../images/decoration/icons/icon8.jpg";
import icon9 from "../../images/decoration/icons/icon9.jpg";
import icon10 from "../../images/decoration/icons/icon10.jpg";
import icon11 from "../../images/decoration/icons/icon11.jpg";
import icon12 from "../../images/decoration/icons/icon12.jpg";
import icon13 from "../../images/decoration/icons/icon13.jpg";
import icon14 from "../../images/decoration/icons/icon14.jpg";
import icon15 from "../../images/decoration/icons/icon15.jpg";
import icon16 from "../../images/decoration/icons/icon16.jpg";
import icon17 from "../../images/decoration/icons/icon17.jpg";
//import icon18 from "../../images/decoration/icons/icon18.jpg";
import icon19 from "../../images/decoration/icons/icon19.jpg";

const reviews = [
  {
		name: "Иерей Сергей Михайлович",
		text: "К огромному сожалению, с Павлов свело горе. На СВО пошиб мой младший брат. Обратились к Павлу за установкой мемориала моим родителям и брату. Павел подошел к работе со всей ответственностью.  Предложил большой выбор гранитных комплексов. Установили комплекс качественно и во время, не к чему придраться, чётко знают, что делают!!! Рекомендую Павла, который работает не на количество, а на качество!! СПАСИБО ВАМ БОЛЬШОЕ!",
		date: "12.09.2024",
	},
	{
		name: "Ирина  Васильевна",
		text: "Работаю с Павлом не первый год. В этом году приводили в порядок  могилу бабушки с дедушкой. Получилось так, что при захоронении соседней могилы у нас урезали кусок участка. Павел нашел решение проблемы и установил шикарный гранитный комплекс, учитывая все мои пожелания. ",
		date: "02.09.2024",
	},
	{
		name: "Александр Иванович",
		text: "Хотим выразить Павлу благодарность за изготовление и установку памятника для родителей. У папы стоял памятник, который с 85 года уже выглядел не очень. Павел предложил поставить один памятник на двоих родителям.  Не смотря, что мы находимся в другом городе, это не помешало Павлу выполнить нашу работу. Спасибо за добросовестную и своевременную установку. Редко встретишь таких людей в этой сфере. Удачи и здоровья вам! С уважением А.И. (г.Орел)",
		date: "31.08.2024",
	},
	{
		name: " Светлана",
		text: "Хочу поблагодарить ребят за проделанные работы на 2х кладбищах. Делали памятник, крест, фотографии и лавочки. Всё четко, быстро и старались чтобы было по бюджету. Сами привезли и установили. В последствии ещё сделали гравировку на уже стоящий памятник.",
		date: "24.06.2024",
	},
	{
		name: "Георгий",
		text: "К поиску компании, которая будет делать гранитный памятник, я подошел со всей ответственностью: сравнивал цены, листал сотни каталогов, оценивал качество, но найти хорошего исполнителя не могла. На кладбище заказывать оказалось очень дорогим «удовольствием».Что же, бывает, но потом случайно узнал о компании ИП Лейко. Созвонились, рассказал все  пожелания. Цены мне показались вполне нормальными, поэтому я уточнил стоимость памятника, который приглянулся, и спросил, можно ли немного изменить один элемент. Работа выполнена в срок, в полном соответствии с согласованным проектом. Я доволен результатом, искреннее человеческое спасибо вам!",
		date: "01.06.2024",
	},
	{
		name: "Людмила",
		text: "Заказывала в компании полный комплекс работ – от демонтажа старого бетонного памятника до изготовления нового и благоустройство могилы. Павел выслушал все мои пожелания, предоставил оптимальный вариант в рамках бюджета – работы было много, но ребята справились менее, чем за месяц. Рекомендую!",
		date: "28.05.2024",
	},
	{
		name: "Алексей",
		text: "К сожалению узнал о Павле  уже после того, как сделал памятник своим родителям. Здесь ассортимент очень хороший, цены адекватные и качество отличное (соседний памятник делала компания Павла. Я тоже заказ сделал – гранитную лавочку приобрел. Доставили быстро, поскольку товар был в наличии. С монтажом тоже проблем не было.",
		date: "20.05.2024",
	},
	{
		name: "Ольга",
		text: "Обращалась к Павлу дважды, и оба раза оставалась довольна работой. Первый раз заказала простой гранитный памятник, но через некоторое время пожалела, что отказалась от гранитных ваз… Спустя год все-таки решила заказать их. Павел рассказал, как правильно хранить вазы зимой, чтоб они не лопнули. Заказ выполнили очень быстро, качество отличное. Спасибо.",
		date: "10.09.2023",
	},
	{
		name: "Анастасия",
		text: "Обратилась за помощью в организацию. Тут делают не только памятные мемориалы для людей, но мы обратились за памятной доской для нашего любимца Тайсона.",
		date: "05.09.2023",
	},
	{
		name: "Святослав",
		text: "Здесь заказывал фотокерамику и ограду на могилку жены. Остался доволен",
		date: "31.08.2023",
	},
	{
		name: "Анатолий Петрович К.",
		text: "С Павлом давно знаком. К сожалению нас познакомила не  очень радостное событие в жизни. Заказывали бюджетный памятник и ограду. Даже скидку получили. Работает качественно, по совести так сказать.",
		date: "20.08.2023",
	},
	{
		name: "Ирина",
		text: "Не у каждого есть возможность поставить дорогой памятник. Павел помог нам с выбором за разумные средства гранитную доску и хорошую ограду. Этот молодой человек с большим опытом работы заслуживает рекомендация",
		date: "12.08.2023",
	},
	{
		name: "Владимир",
		text: "Заказывали у Павла Геннадиевича комплекс работ на кладбище, начиная от выравнивания холма, заканчивая гранитным комплексом. Это профессионал своего дела, из разговора понял, что работает в данной сфере с 15 лет. Работу нам сделали качественно, быстро, нам все очень понравилось",
		date: "08.08.2023",
	},
	{
		name: "Чернышенко",
		text: "Обращались к Павлу по рекомендации. Нам нужно было покрасить ограду, если често, то мы подумали, что на могиле стоит новая ограда на столько качественно была проделана работа.",
		date: "06.08.2023",
	},
	{
		name: "Евгений П.",
		text: "Много лет назад переехал жить в другую страну. Ухаживать за могилой  родных нет возможности. Обратились к Павлу и уже который год могила чистая и ухоженная, со свежими цветами. Каждый раз нам присылают фото о проделанной работе.",
		date: "21.07.2023",
	},
	{
		name: "Валентина А.",
		text: "Заказывала у Павла для мамы. Были учтены все пожелания. Очень ответственный подход к работе",
		date: "09.07.2023",
	},
	{
		name: "Геннадий Владимирович",
		text: "Дважды заказывал здесь памятники из гранита, и оба раза остался доволен ими и оперативностью работы. Выполнили работу раньше обговоренного срока,  доставила изделия и аккуратно установили их с учётом моих пожеланий. Буду рекомендовать Павла знакомым.",
		date: "18.06.2023",
	},
	{
		name: "Надежда Петровна",
		text: "Нам пенсионерам сложно найти доступный памятник, обратились к Павлу, хороший молодой человек, сделал нам скидку и в подарок сделал надписи на памятнике.",
		date: "01.06.2023",
	},
	{
		name: "Ольга",
		text: "Мы с семьей часто приезжаем на могилу к бабушке с дедушкой решили, что нужно поставить там лавку. Нам посоветовали обратиться к Павлу.  Цена адекватная, доставку и монтаж сделали довольно быстро, да и лавка смотрится очень аккуратно.",
		date: "06.10.2022",
	},
	{
		name: "Валентин П.",
		text: "Заказывал памятник и цветник в 2020. По прошествии 4 лет все как новенькое. На портрет Павел нанес покрытие, что даже при дожде он не исчезает, как на памятниках рядом. В этом году заказали ограду цена более чем приятная",
		date: "08.06.2022",
	},
	{
		name: "Олег Бондалетов",
		text: "Павла  рекомендовала коллега. У нас было определенное видение, как должно выглядеть место захоронения. Павел подошел к этому вопросу со вниманием и оправдал наши ожидания. Спасибо",
		date: "25.05.2022",
	},
	{
		name: "Наталья",
		text: "Обращаюсь к Павлу Геннадиевичу не первый раз. Заказывала памятный комплекс для своего папы. Работа выполнена качественно по доступной цене еще и скидку сделал, во многих компаниях итоговая сумма была в разы больше.",
		date: "18.05.2022",
	},
];

const ourWorks = [
  {
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work74,
		key: "work74",
	},

  {
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work73,
		key: "work73",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work1,
		key: "work1",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work2,
		key: "work2",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work3,
		key: "work3",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work4,
		key: "work4",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work5,
		key: "work5",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work6,
		key: "work6",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work7,
		key: "work7",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work8,
		key: "work8",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work9,
		key: "work9",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work10,
		key: "work10",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work11,
		key: "work11",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work12,
		key: "work12",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work13,
		key: "work13",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work14,
		key: "work14",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work15,
		key: "work15",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work16,
		key: "work16",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work17,
		key: "work17",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work18,
		key: "work18",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work19,
		key: "work19",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work20,
		key: "work20",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work21,
		key: "work21",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work22,
		key: "work22",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work23,
		key: "work23",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work24,
		key: "work24",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work25,
		key: "work25",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work26,
		key: "work26",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work27,
		key: "work27",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work28,
		key: "work28",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work29,
		key: "work29",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work30,
		key: "work30",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work31,
		key: "work31",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work32,
		key: "work32",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work33,
		key: "work33",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work34,
		key: "work34",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work35,
		key: "work35",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work36,
		key: "work36",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work37,
		key: "work37",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work38,
		key: "work38",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work39,
		key: "work39",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work40,
		key: "work40",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work41,
		key: "work41",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work42,
		key: "work42",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work43,
		key: "work43",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work44,
		key: "work44",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work45,
		key: "work45",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work46,
		key: "work46",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work47,
		key: "work47",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work48,
		key: "work48",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work49,
		key: "work49",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work50,
		key: "work50",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work51,
		key: "work51",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work52,
		key: "work52",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work53,
		key: "work53",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work54,
		key: "work54",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work55,
		key: "work55",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work56,
		key: "work56",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work57,
		key: "work57",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work58,
		key: "work58",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work59,
		key: "work59",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work60,
		key: "work60",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work61,
		key: "work61",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work62,
		key: "work62",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work63,
		key: "work63",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work64,
		key: "work64",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work65,
		key: "work65",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work66,
		key: "work66",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work67,
		key: "work67",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work68,
		key: "work68",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work69,
		key: "work69",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work70,
		key: "work70",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work71,
		key: "work71",
	},
	{
		name: "Могила захоронение гранитный памятник. Пример работы",
		text: "",
		img: work72,
		key: "work72",
	},
];

const monumRez = [
	{
		name: "памятник резной. ПР-1",
		text: "",
		img: monumentsRez1,
		key: "monumentsRez1",
	},
	{
		name: "памятник резной. ПР-2",
		text: "",
		img: monumentsRez2,
		key: "monumentsRez2",
	},
	{
		name: "памятник резной. ПР-3",
		text: "",
		img: monumentsRez3,
		key: "monumentsRez3",
	},
	{
		name: "памятник резной. ПР-4",
		text: "",
		img: monumentsRez4,
		key: "monumentsRez4",
	},
	{
		name: "памятник резной. ПР-5",
		text: "",
		img: monumentsRez5,
		key: "monumentsRez5",
	},
	{
		name: "памятник резной. ПР-6",
		text: "",
		img: monumentsRez6,
		key: "monumentsRez6",
	},
	{
		name: "памятник резной. ПР-7",
		text: "",
		img: monumentsRez7,
		key: "monumentsRez7",
	},
	{
		name: "памятник резной. ПР-7",
		text: "",
		img: monumentsRez8,
		key: "monumentsRez8",
	},
	{
		name: "памятник резной. ПР-9",
		text: "",
		img: monumentsRez9,
		key: "monumentsRez9",
	},
	{
		name: "памятник резной. ПР-10",
		text: "",
		img: monumentsRez10,
		key: "monumentsRez10",
	},
	{
		name: "памятник резной. ПР-11",
		text: "",
		img: monumentsRez11,
		key: "monumentsRez11",
	},
	{
		name: "памятник резной. ПР-12",
		text: "",
		img: monumentsRez12,
		key: "monumentsRez12",
	},
	{
		name: "памятник резной. ПР-13",
		text: "",
		img: monumentsRez13,
		key: "monumentsRez13",
	},
	{
		name: "памятник резной. ПР-14",
		text: "",
		img: monumentsRez14,
		key: "monumentsRez14",
	},
	{
		name: "памятник резной. ПР-15",
		text: "",
		img: monumentsRez15,
		key: "monumentsRez15",
	},
	{
		name: "памятник резной. ПР-16",
		text: "",
		img: monumentsRez16,
		key: "monumentsRez16",
	},
	{
		name: "памятник резной. ПР-17",
		text: "",
		img: monumentsRez17,
		key: "monumentsRez17",
	},
	{
		name: "памятник резной. ПР-18",
		text: "",
		img: monumentsRez18,
		key: "monumentsRez18",
	},
	{
		name: "памятник резной. ПР-19",
		text: "",
		img: monumentsRez19,
		key: "monumentsRez19",
	},
	{
		name: "памятник резной. ПР-20",
		text: "",
		img: monumentsRez20,
		key: "monumentsRez20",
	},
	{
		name: "памятник резной. ПР-21",
		text: "",
		img: monumentsRez21,
		key: "monumentsRez21",
	},
	{
		name: "памятник резной. ПР-22",
		text: "",
		img: monumentsRez22,
		key: "monumentsRez22",
	},
	{
		name: "памятник резной. ПР-23",
		text: "",
		img: monumentsRez23,
		key: "monumentsRez23",
	},
	{
		name: "памятник резной. ПР-24",
		text: "",
		img: monumentsRez24,
		key: "monumentsRez24",
	},
	{
		name: "памятник резной. ПР-25",
		text: "",
		img: monumentsRez25,
		key: "monumentsRez25",
	},
	{
		name: "памятник резной. ПР-26",
		text: "",
		img: monumentsRez26,
		key: "monumentsRez26",
	},
	{
		name: "памятник резной. ПР-27",
		text: "",
		img: monumentsRez27,
		key: "monumentsRez27",
	},
	{
		name: "памятник резной. ПР-28",
		text: "",
		img: monumentsRez28,
		key: "monumentsRez28",
	},
	{
		name: "памятник резной. ПР-29",
		text: "",
		img: monumentsRez29,
		key: "monumentsRez29",
	},
	{
		name: "памятник резной. ПР-30",
		text: "",
		img: monumentsRez30,
		key: "monumentsRez30",
	},
	{
		name: "памятник резной. ПР-31",
		text: "",
		img: monumentsRez31,
		key: "monumentsRez31",
	},
	{
		name: "памятник резной. ПР-32",
		text: "",
		img: monumentsRez32,
		key: "monumentsRez32",
	},
	{
		name: "памятник резной. ПР-33",
		text: "",
		img: monumentsRez33,
		key: "monumentsRez33",
	},
	{
		name: "памятник резной. ПР-34",
		text: "",
		img: monumentsRez34,
		key: "monumentsRez34",
	},
	{
		name: "памятник резной. ПР-35",
		text: "",
		img: monumentsRez35,
		key: "monumentsRez35",
	},
	{
		name: "памятник резной. ПР-36",
		text: "",
		img: monumentsRez36,
		key: "monumentsRez36",
	},
	{
		name: "памятник резной. ПР-37",
		text: "",
		img: monumentsRez37,
		key: "monumentsRez37",
	},
	{
		name: "памятник резной. ПР-38",
		text: "",
		img: monumentsRez39,
		key: "monumentsRez39",
	},
	{
		name: "памятник резной. ПР-39",
		text: "",
		img: monumentsRez40,
		key: "monumentsRez40",
	},
	{
		name: "памятник резной. ПР-40",
		text: "",
		img: monumentsRez41,
		key: "monumentsRez41",
	},
	{
		name: "памятник резной. ПР-41",
		text: "",
		img: monumentsRez42,
		key: "monumentsRez42",
	},
	{
		name: "памятник резной. ПР-42",
		text: "",
		img: monumentsRez43,
		key: "monumentsRez43",
	},
	{
		name: "памятник резной. ПР-43",
		text: "",
		img: monumentsRez44,
		key: "monumentsRez44",
	},
	{
		name: "памятник резной. ПР-44",
		text: "",
		img: monumentsRez45,
		key: "monumentsRez45",
	},
	{
		name: "памятник резной. ПР-45",
		text: "",
		img: monumentsRez46,
		key: "monumentsRez46",
	},
	{
		name: "памятник резной. ПР-46",
		text: "",
		img: monumentsRez47,
		key: "monumentsRez47",
	},
	{
		name: "памятник резной. ПР-47",
		text: "",
		img: monumentsRez48,
		key: "monumentsRez48",
	},
	{
		name: "памятник резной. ПР-48",
		text: "",
		img: monumentsRez49,
		key: "monumentsRez49",
	},
	{
		name: "памятник резной. ПР-49",
		text: "",
		img: monumentsRez50,
		key: "monumentsRez50",
	},
	{
		name: "памятник резной. ПР-50",
		text: "",
		img: monumentsRez51,
		key: "monumentsRez51",
	},
	{
		name: "памятник резной. ПР-51",
		text: "",
		img: monumentsRez52,
		key: "monumentsRez52",
	},
	{
		name: "памятник резной. ПР-52",
		text: "",
		img: monumentsRez53,
		key: "monumentsRez53",
	},
];

const monumDuble = [
	{
		name: "Двойной памятник гранитный ДПГ-1",
		text: "",
		img: monumentsDuble1,
		key: "monumentsDuble1",
	},
	{
		name: "Двойной памятник гранитный ДПГ-2",
		text: "",
		img: monumentsDuble2,
		key: "monumentsDuble2",
	},
	{
		name: "Двойной памятник гранитный ДПГ-3",
		text: "",
		img: monumentsDuble3,
		key: "monumentsDuble3",
	},
	{
		name: "Двойной памятник гранитный ДПГ-4",
		text: "",
		img: monumentsDuble4,
		key: "monumentsDuble4",
	},
	{
		name: "Двойной памятник гранитный ДПГ-5",
		text: "",
		img: monumentsDuble5,
		key: "monumentsDuble5",
	},
	{
		name: "Двойной памятник гранитный ДПГ-6",
		text: "",
		img: monumentsDuble6,
		key: "monumentsDuble6",
	},
	{
		name: "Двойной памятник гранитный ДПГ-7",
		text: "",
		img: monumentsDuble7,
		key: "monumentsDuble7",
	},
];

const monumHorizont = [
	{
		name: "Памятник гранитный горизонтальный ПГ-1",
		text: "",
		img: monumentsHorizont1,
		key: "monumentsHorizont1",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-2",
		text: "",
		img: monumentsHorizont2,
		key: "monumentsHorizont2",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-3",
		text: "",
		img: monumentsHorizont3,
		key: "monumentsHorizont3",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-4",
		text: "",
		img: monumentsHorizont4,
		key: "monumentsHorizont4",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-5",
		text: "",
		img: monumentsHorizont5,
		key: "monumentsHorizont5",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-6",
		text: "",
		img: monumentsHorizont6,
		key: "monumentsHorizont6",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-7",
		text: "",
		img: monumentsHorizont7,
		key: "monumentsHorizont7",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-8",
		text: "",
		img: monumentsHorizont8,
		key: "monumentsHorizont8",
	},
	{
		name: "Памятник гранитный горизонтальный ПГ-9",
		text: "",
		img: monumentsHorizont9,
		key: "monumentsHorizont9",
	},
];

const monumVert = [
	{
		name: "Памятник гранитный вертикальный ПВ-1",
		text: "",
		img: monumentsVert1,
		key: "monumentsVert1",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-2",
		text: "",
		img: monumentsVert2,
		key: "monumentsVert2",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-3",
		text: "",
		img: monumentsVert3,
		key: "monumentsVert3",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-4",
		text: "",
		img: monumentsVert4,
		key: "monumentsVert4",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-5",
		text: "",
		img: monumentsVert5,
		key: "monumentsVert5",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-6",
		text: "",
		img: monumentsVert6,
		key: "monumentsVert6",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-7",
		text: "",
		img: monumentsVert7,
		key: "monumentsVert7",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-8",
		text: "",
		img: monumentsVert8,
		key: "monumentsVert8",
	},
	{
		name: "Памятник гранитный вертикальный ПВ-9",
		text: "",
		img: monumentsVert9,
		key: "monumentsVert9",
	},
];

const developmentTable = [
	{
		name: "Лавочка из гранита ГЛ-4",
		text: "",
		img: table1,
		key: "table1",
	},
	{
		name: "Лавочка из гранита ГЛ-7",
		text: "",
		img: table2,
		key: "table2",
	},
	{
		name: "Столик из гранита ГС-1",
		text: "",
		img: table3,
		key: "table3",
	},
	{
		name: "Столик из гранита ГС-2",
		text: "",
		img: table4,
		key: "table4",
	},
	{
		name: "Столик из гранита ГС-3",
		text: "",
		img: table5,
		key: "table5",
	},
];

const developmentTile = [
	{
		name: "Плитка тротуарная П-1",
		text: "",
		img: tile1,
		key: "tile1",
	},
	{
		name: "Плитка тротуарная П-2",
		text: "",
		img: tile2,
		key: "tile2",
	},
	{
		name: "Плитка тротуарная П-3",
		text: "",
		img: tile3,
		key: "tile3",
	},
	{
		name: "Плитка тротуарная П-4",
		text: "",
		img: tile4,
		key: "tile4",
	},
	{
		name: "Плитка тротуарная П-5",
		text: "",
		img: tile5,
		key: "tile5",
	},
	{
		name: "Плитка тротуарная П-6",
		text: "",
		img: tile6,
		key: "tile6",
	},
	{
		name: "Плитка тротуарная П-7",
		text: "",
		img: tile7,
		key: "tile7",
	},
];

const developmentFences = [
	{
		name: "Кованая ограда на могилу «Бриз»",
		text: "",
		img: fences1,
		key: "fences1",
	},
	{
		name: "Кованая ограда на могилу «Бриз» цоколь",
		text: "",
		img: fences2,
		key: "fences2",
	},
	{
		name: "Кованая ограда на могилу «Волна»",
		text: "",
		img: fences3,
		key: "fences3",
	},
	{
		name: "Кованая ограда на могилу «Гранат»",
		text: "",
		img: fences4,
		key: "fences4",
	},
	{
		name: "Кованая ограда на могилу «Грот»",
		text: "",
		img: fences5,
		key: "fences5",
	},
	{
		name: "Кованая ограда на могилу «Капля»",
		text: "",
		img: fences6,
		key: "fences6",
	},
	{
		name: "Кованая ограда на могилу «Капля»",
		text: "",
		img: fences7,
		key: "fences7",
	},
	{
		name: "Кованая ограда на могилу «Кольцо»",
		text: "",
		img: fences8,
		key: "fences8",
	},
	{
		name: "Кованая ограда на могилу «Лилия»",
		text: "",
		img: fences9,
		key: "fences9",
	},
	{
		name: "Кованая ограда на могилу «Новая»",
		text: "",
		img: fences10,
		key: "fences10",
	},
	{
		name: "Кованая ограда на могилу «Ода»",
		text: "",
		img: fences11,
		key: "fences11",
	},
	{
		name: "Кованая ограда на могилу «Пика»",
		text: "",
		img: fences12,
		key: "fences12",
	},
	{
		name: "Кованая ограда на могилу «Ромб»",
		text: "",
		img: fences13,
		key: "fences13",
	},
	{
		name: "Кованая ограда на могилу «Чайка»",
		text: "",
		img: fences14,
		key: "fences14",
	},
	{
		name: "Кованая ограда на могилу «Квадрат»",
		text: "",
		img: fences15,
		key: "fences15",
	},
	{
		name: "Кованая ограда на могилу «Арка»",
		text: "",
		img: fences16,
		key: "fences16",
	},
	{
		name: "Кованая ограда на могилу «Венера»",
		text: "",
		img: fences17,
		key: "fences17",
	},
	{
		name: "Кованая ограда на могилу «Лиана»",
		text: "",
		img: fences18,
		key: "fences18",
	},
	{
		name: "Ограда на могилу «Новая крест»",
		text: "",
		img: fences19,
		key: "fences19",
	},
	{
		name: "Ограда на могилу «Овен»",
		text: "",
		img: fences20,
		key: "fences20",
	},
	{
		name: "Ограда на могилу «Овен»",
		text: "",
		img: fences21,
		key: "fences21",
	},
	{
		name: "Ограда на могилу «София»",
		text: "",
		img: fences22,
		key: "fences22",
	},
	{
		name: "Ограда на могилу «Тюльпан»",
		text: "",
		img: fences23,
		key: "fences23",
	},
	{
		name: "Ограда на могилу «Этюд»",
		text: "",
		img: fences24,
		key: "fences24",
	},
];

const developmentChips = [
	{
		name: "Крошка гранитная К-1",
		text: "",
		img: chip1,
		key: "chip1",
	},
	{
		name: "Крошка гранитная  К-2",
		text: "",
		img: chip2,
		key: "chip2",
	},
	{
		name: "Крошка гранитная  К-3",
		text: "",
		img: chip3,
		key: "chip3",
	},
	{
		name: "Крошка гранитная  К-4",
		text: "",
		img: chip4,
		key: "chip4",
	},
	{
		name: "Крошка гранитная  К-5",
		text: "",
		img: chip5,
		key: "chip5",
	},
	{
		name: "Крошка гранитная  К-6",
		text: "",
		img: chip6,
		key: "chip6",
	},
];

const developmentVases = [
	{
		name: "Ваза гранитная В-1",
		text: "",
		img: vase1,
		key: "vase1",
	},
	{
		name: "Ваза гранитная В-2",
		text: "",
		img: vase2,
		key: "vase2",
	},
	{
		name: "Ваза гранитная В-3",
		text: "",
		img: vase3,
		key: "vase3",
	},
	{
		name: "Ваза гранитная В-4",
		text: "",
		img: vase4,
		key: "vase4",
	},
	{
		name: "Лампада гранитная Л-1",
		text: "",
		img: vase5,
		key: "vase5",
	},
	{
		name: "Лампада гранитная Л-2",
		text: "",
		img: vase6,
		key: "vase6",
	},
	{
		name: "Лампада гранитная Л-3",
		text: "",
		img: vase7,
		key: "vase7",
	},
	{
		name: "Лампада гранитная Л-4",
		text: "",
		img: vase8,
		key: "vase8",
	},
	{
		name: "Лампада гранитная Л-5",
		text: "",
		img: vase9,
		key: "vase9",
	},
	{
		name: "Лампада гранитная Л-6",
		text: "",
		img: vase10,
		key: "vase10",
	},
];

const decorationCandles = [
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle1,
		key: "candle1",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle2,
		key: "candle2",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle3,
		key: "candle3",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: candle4,
	//},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle5,
		key: "candle5",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle6,
		key: "candle6",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle7,
		key: "candle7",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle8,
		key: "candle8",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle9,
		key: "candle9",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle10,
		key: "candle10",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: candle11,
	//},
	//{
	//	name: "",
	//	text: "",
	//	img: candle12,
	//},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle13,
		key: "candle13",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle14,
		key: "candle14",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle15,
		key: "candle15",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle16,
		key: "candle16",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: candle17,
	//},
	//{
	//	name: "",
	//	text: "",
	//	img: candle18,
	//},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle19,
		key: "candle19",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle20,
		key: "candle20",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: candle21,
	//},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle22,
		key: "candle22",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: candle23,
	//},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle24,
		key: "candle24",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle25,
		key: "candle25",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle26,
		key: "candle26",
	},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle27,
		key: "candle27",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: candle28,
	//},
	{
		name: "Гравировка на памятник свеча",
		text: "",
		img: candle29,
		key: "candle29",
	},
];

const decorationFlowers = [
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower1,
		key: "flower1",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower2,
		key: "flower2",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower3,
		key: "flower3",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower4,
		key: "flower4",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower5,
		key: "flower5",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower6,
		key: "flower6",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower7,
		key: "flower7",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower8,
		key: "flower8",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower9,
		key: "flower9",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower10,
		key: "flower10",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower11,
		key: "flower11",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower12,
		key: "flower12",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower13,
		key: "flower13",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower14,
		key: "flower14",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower15,
		key: "flower15",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower16,
		key: "flower16",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower17,
		key: "flower17",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower18,
		key: "flower18",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower19,
		key: "flower19",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower20,
		key: "flower20",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower21,
		key: "flower21",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower22,
		key: "flower22",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower23,
		key: "flower23",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower24,
		key: "flower24",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower25,
		key: "flower25",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower26,
		key: "flower26",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower27,
		key: "flower27",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower28,
		key: "flower28",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower29,
		key: "flower29",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower30,
		key: "flower30",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower31,
		key: "flower31",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower32,
		key: "flower32",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower33,
		key: "flower33",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower34,
		key: "flower34",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower35,
		key: "flower35",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower36,
		key: "flower36",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: flower37,
	//},
	//{
	//	name: "",
	//	text: "",
	//	img: flower38,
	//},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower39,
		key: "flower39",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower40,
		key: "flower40",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: flower41,
	//},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower42,
		key: "flower42",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower43,
		key: "flower43",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower44,
		key: "flower44",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower45,
		key: "flower45",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower46,
		key: "flower46",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower47,
		key: "flower47",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower48,
		key: "flower48",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower49,
		key: "flower49",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower50,
		key: "flower50",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower51,
		key: "flower51",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower52,
		key: "flower52",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower53,
		key: "flower53",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower54,
		key: "flower54",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower55,
		key: "flower55",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower56,
		key: "flower56",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower57,
		key: "flower57",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower58,
		key: "flower58",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower59,
		key: "flower59",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower60,
		key: "flower60",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower61,
		key: "flower61",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower62,
		key: "flower62",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower63,
		key: "flower63",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower64,
		key: "flower64",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower65,
		key: "flower65",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower66,
		key: "flower66",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower67,
		key: "flower67",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower68,
		key: "flower68",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower69,
		key: "flower69",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower70,
		key: "flower70",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower71,
		key: "flower71",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower72,
		key: "flower72",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower73,
		key: "flower73",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower74,
		key: "flower74",
	},
	{
		name: "Гравировка на памятник цветы",
		text: "",
		img: flower75,
		key: "flower75",
	},
];

const decorationCrosses = [
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross1,
		key: "cross1",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross2,
		key: "cross2",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross3,
		key: "cross3",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross4,
		key: "cross4",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross5,
		key: "cross5",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross6,
		key: "cross6",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross7,
		key: "cross7",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross8,
		key: "cross8",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross9,
		key: "cross9",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross10,
		key: "cross10",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross11,
		key: "cross11",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross12,
		key: "cross12",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross13,
		key: "cross13",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross14,
		key: "cross14",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross15,
		key: "cross15",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross16,
		key: "cross16",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross17,
		key: "cross17",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross18,
		key: "cross18",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross19,
		key: "cross19",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross20,
		key: "cross20",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross21,
		key: "cross21",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross22,
		key: "cross22",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross23,
		key: "cross23",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross24,
		key: "cross24",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross25,
		key: "cross25",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross26,
		key: "cross26",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross27,
		key: "cross27",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross28,
		key: "cross28",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross29,
		key: "cross29",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross30,
		key: "cross30",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross31,
		key: "cross31",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: cross32,
	//},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross33,
		key: "cross33",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross34,
		key: "cross34",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross35,
		key: "cross35",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross36,
		key: "cross36",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross37,
		key: "cross37",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross38,
		key: "cross38",
	},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross39,
		key: "cross39",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: cross40,
	//},
	{
		name: "Гравировка на памятник крест",
		text: "",
		img: cross41,
		key: "cross41",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: cross42,
	//},
];

const decorationIcons = [
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon1,
		key: "icon1",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon2,
		key: "icon2",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon3,
		key: "icon3",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon4,
		key: "icon4",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon5,
		key: "icon5",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon6,
		key: "icon6",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon7,
		key: "icon7",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon8,
		key: "icon8",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon9,
		key: "icon9",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon10,
		key: "icon10",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon11,
		key: "icon11",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon12,
		key: "icon12",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon13,
		key: "icon13",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon14,
		key: "icon14",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon15,
		key: "icon15",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon16,
		key: "icon16",
	},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon17,
		key: "icon17",
	},
	//{
	//	name: "",
	//	text: "",
	//	img: icon18,
	//},
	{
		name: "Гравировка на памятник икона",
		text: "",
		img: icon19,
		key: "icon19",
	},
];

export {
	reviews,
	ourWorks,
	monumRez,
	monumDuble,
	monumHorizont,
	monumVert,
	developmentTable,
	developmentTile,
	developmentFences,
	developmentChips,
	developmentVases,
	decorationCandles,
	decorationFlowers,
	decorationCrosses,
	decorationIcons,
};
